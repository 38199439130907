import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { adminOnly, getValueByKey, hasAccess } from '../../../../Utils/AWSBucket/AWSConfiguration';

const PackagesSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Packages" icon="file-add">
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Active Users" route="/user-packages" />}
        </SidebarCategory>
    )
};

export default PackagesSidebar;