import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { adminOnly, getValueByKey, hasAccess } from '../../../../Utils/AWSBucket/AWSConfiguration';

const SettingSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Setting" icon="cog">
            {hasAccess(authslice?.loggedAdminRole, adminOnly) &&  <SidebarLink title="Calender" route="/calender" />}
        </SidebarCategory>
    );
};

export default SettingSidebar;