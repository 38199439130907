import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { adminOnly, getValueByKey, hasAccess } from '../../../../Utils/AWSBucket/AWSConfiguration';

const ManageUser = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Manage User" icon="select">
            {hasAccess(authslice?.loggedAdminRole, adminOnly) &&  <SidebarLink title={`Create User`} route="/create_user" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) &&  <SidebarLink title="Update User" route="/update-user" />}
        </SidebarCategory>
    )
}

export default ManageUser