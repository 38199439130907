import React from 'react'
import SidebarCategory from '../SidebarCategory';
import SidebarLink from '../SidebarLink';
import { useSelector, useDispatch } from 'react-redux';
import { adminCounterBooking, adminOnly, hasAccess, hideForAll } from '../../../../Utils/AWSBucket/AWSConfiguration';

const UserSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="User" icon="user">
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Registered Users" route="/user" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Admin Users" route="/admin-users" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Loyalty Points" route="/loyalty-points-management" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Generate Slot" route="/generate-slot" />}
        </SidebarCategory>
    )
};

export default UserSidebar;