import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { adminCounterBooking, adminOnly, getValueByKey, hasAccess, hideForAll } from '../../../../Utils/AWSBucket/AWSConfiguration';

const BookingsSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Bookings" icon="calendar-full">
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Calender" route="/calenderv2" />}
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Booking's List" route="/bookings" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Notify Me" route="/notify_me" />}
        </SidebarCategory>
    );
};

export default BookingsSidebar