import React from 'react'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'

const SportsList = () => {
    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm={6} md={9} lg={9}>
                                    <h3>Sports</h3>
                                </Col>
                                <Col sm={6} md={3} lg={3}>
                                    <Button color="warning" className='m-0'>
                                        Add Sport
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default SportsList