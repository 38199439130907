/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Card, CardBody, Button, Row, Spinner } from "reactstrap";
import axios from "../../../customAxios";
import { useNavigate } from "react-router-dom";
import { environment } from "../../env";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../../shared/components/table/components/ReactTableCustomizer";
import { AiFillEye, AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import "./User.css";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import { useForm } from "react-hook-form";

const UsersListCard = ({ control }) => {
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
  const [userData, setUserData] = useState([]);
  const [initialPage, setInitialPage] = useState(0);
  const [mannualLimits, setMannualLimits] = useState([
    10, 20, 30, 40, 50, 100, 200, 500, 1000,
  ]);
  const [pageCount, setpageCount] = useState(0);

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [search, setSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const navigate = useNavigate();

  let newValue;

  const getUser = async () => {
    setIsLoading(true)
    let page = parseInt(localStorage.getItem("RegisteredUserPage"));
    let pageSize = parseInt(localStorage.getItem("RegisteredUserPageSize"));
    const usersUrl = `${environment.baseUrl}api/user/registration-filter/?page=${page}&page_size=${pageSize}`;
    const result = await axios(usersUrl).then((response) => {
      setpageCount(Math.ceil(response.data.count / pageSize));
      setUserData(response.data.results);
      setIsLoading(false)
    });
  };

  var UserDataObject = [];
  for (let i = 0; i < userData.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["email"] = userData[i].email.toString();
    item["first_name"] =
      userData[i].first_name === "null"
        ? ""
        : userData[i].first_name.toString();
    item["phone_no"] = (
      <div className="right-space user-phone">
        {userData[i].phone_no === "null" ? "" : userData[i].phone_no}
      </div>
    );
    item["source"] = <div className="right-space">{userData[i].source}</div>;
    item["role"] = <div className="right-space">{userData[i].role}</div>;
    item["created"] = (
      <div className="right-space">{userData[i].registration_date}</div>
    );
    item["view"] = (
      <div className="right-space">
        <div
          className="modal-view-btn"
          onClick={() => handleViewDetails(userData[i].id)}
        >
          <AiFillEye style={{ fontSize: 20 }} />
        </div>
      </div>
    );
    item["activate"] = (
      <div className="right-space">
        {userData[i].active ? (
          <div className="check-activated">
            <AiOutlineCheck
              onClick={() => handleActivate(userData[i].id, userData[i].active)}
              style={{ fontSize: 20 }}
            />
          </div>
        ) : (
          <div className="cross-activate">
            <AiOutlineClose
              onClick={() => handleActivate(userData[i].id, userData[i].active)}
              style={{ fontSize: 20 }}
            />
          </div>
        )}
      </div>
    );
    UserDataObject.push(item);
  }

  const RegisteredUserData = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Customer Name",
        accessor: "first_name",
        disableGlobalFilter: false,
        width: 105,
      },
      {
        Header: "Email",
        accessor: "email",
        disableGlobalFilter: false,
        width: 150,
      },
      {
        Header: "Phone No",
        accessor: "phone_no",
        disableGlobalFilter: true,
        width: 90,
      },
      {
        Header: "Source",
        accessor: "source",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Role",
        accessor: "role",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Create Date",
        accessor: "created",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "View",
        accessor: "view",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      },
      {
        Header: "Action",
        accessor: "activate",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      },
    ],
    data: UserDataObject,
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [100000, 200000, 300000, 400000],
    placeholder: "Search by Name...",
  };

  const handleViewDetails = (id) => {
    navigate(`/user_details/${id}`);
  };

  const handleActivate = (id, activate) => {
    const activateUserURL = `${environment.baseUrl}activate/`;
    const data = {
      id: id,
      active: !activate,
    };

    axios.post(activateUserURL, data).then((response) => {
      alert(response.data.message);
      getUser();
    });
  };

  async function handleLimitChange(e) {
    localStorage.setItem("RegisteredUserPageSize", e.target.value);
    setInitialPage(0);
    await getUser();
  }

  useEffect(() => {
    localStorage.setItem("RegisteredUserPage", 1);
    localStorage.setItem("RegisteredUserPageSize", 10);
    getUser();
    if (localStorage.getItem("Token")) {
    } else {
      alert("You are not logged In. Please LogIn");
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setInitialPage(initialPage);
  }, [initialPage]);

  const HandlePagination = async (data) => {
    setUserData([]);
    let currentPage = data.selected + 1;
    localStorage.setItem("RegisteredUserPage", currentPage);
    getUser();
  };

  const [userSearchValue, setUserSearchValue] = useState()
  const userSearch = (searchValue) => {
    setUserSearchValue(searchValue)
    if (searchValue.length <= 0) {
      debouncedThing();
    }
  };

  const getData = () => {
    setIsLoading(true)
    const FilterUserURL = `${environment.baseUrl}filter-user/${userSearchValue}`;
    const result = axios.get(FilterUserURL).then((filterUserResponse) => {
      let pageSize = parseInt(localStorage.getItem("RegisteredUserPageSize"));
      setpageCount(Math.ceil(10 / pageSize));
      setUserData(filterUserResponse.data);
      setIsLoading(false)
    });
  };

  const clearData = () => {
    setUserSearchValue("")
    debouncedThing();
  }

  const debouncedThing = _.debounce(getUser, 1000);

  // will cancel the execution of thing if executed before 1 second
  debouncedThing.cancel();

  return (
    <Row>
      <Col sm={12} md={12} lg={12} className="mb-4">
        <Card>
          <CardBody>
            <Row>
              <Col md={6} lg={6}>
                <h3>Registered User List</h3>
              </Col>
              <Col md={6} lg={6} className="text-end">

              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col sm={12} md={12} lg={12}>
        <Card>
          <CardBody>
            <form>
              <Row>
                <Col sm={9} md={9} lg={9} className="text-end">
                  <div className="filter-fields">
                    <input
                      type="text"
                      name="searchvalue"
                      placeholder="Search by name, email, and phone number"
                      className="w-100"
                      {...register("searchvalue")}
                      value={userSearchValue}
                      onChange={(e) => userSearch(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm={3} md={3} lg={3} className="text-end">
                  <Row className="m-0">
                    <Col sm={6} md={6} lg={6}>
                      <Button color="warning" size="sm" className="m-0 w-100" onClick={() => getData()}>Search</Button>
                    </Col>
                    <Col sm={6} md={6} lg={6}>
                      <Button color="secondary" size="sm" className="m-0 w-100" onClick={() => clearData()}>Clear</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Col>
      <Col md={12} lg={12} className="mb-4">
        <Card className="mt-2">
          <CardBody>
            {isLoading && (
              <div class="loader-div">
                <span class="loader">
                  <div className="center-spinner">
                    <Spinner className="table-fetch-spinner" />
                  </div>
                </span>
              </div>
            )}

            <div className="table-id-desabled pagination-hide hide-filtername hover-effect hide-table-costomizer second-child-space">
              <ReactTableBase
                key={withSearchEngine ? "searchable" : "common"}
                columns={RegisteredUserData.columns}
                data={RegisteredUserData.data}
                tableConfig={tableConfig}
              />
            </div>

            <div className="custom-pagination">
              <div className="records-per-page">
                <select
                  value={localStorage.getItem("RegisteredUserPageSize")}
                  onChange={handleLimitChange}
                  name="limit"
                  id="select-pagination"
                >
                  {mannualLimits &&
                    mannualLimits.map((mannualLimits) => (
                      <option value={mannualLimits}>{mannualLimits}</option>
                    ))}
                </select>
                &nbsp;&nbsp;&nbsp;records per page
              </div>

              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={HandlePagination}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={initialPage}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UsersListCard;
