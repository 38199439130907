import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { adminOnly, getValueByKey, hasAccess } from '../../../../Utils/AWSBucket/AWSConfiguration';

const AdsSpaceSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Ads Space" icon="indent-increase">
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Ads Space List" route="/ad-space-list" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Ads Analysis" route="/ads-impressions" />}
        </SidebarCategory>
    );
};

export default AdsSpaceSidebar