import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Table, Button, Row, Container } from 'reactstrap';
import { GetPromotionV2, DeletePromotionV2, GetUserPromotionV2 } from '../Reuse/ApiCalls';
import { useNavigate } from "react-router-dom";
import swal from '@sweetalert/with-react';
import { AiOutlineEdit } from 'react-icons/ai';

const PromotionV2List = () => {
  const navigate = useNavigate();
  const [promotionList, setPromotionList] = useState([]);
  const [userPromotionList, setUserPromotionList] = useState([]);

  const getPromotions = () => {
    GetPromotionV2().then((response) => {
      setPromotionList(response)
    })
  };

  const getUserPromotions = () => {
    GetUserPromotionV2().then((response) => {
      setUserPromotionList(response)
    })
  };

  function handleEdit(id) {
    navigate(`/editpromotionsv2/${id}`);
  }

  const handleAddPromotion = () => {
    navigate("/promotionsv2");
  }


  useEffect(() => {
    getPromotions();
    getUserPromotions();
  }, []);

  const deletePromotion = (id) => {
    swal("Are you sure? Want to delete", {
      dangerMode: true,
      buttons: true,
      icon: "warning",
    }).then((result) => {
      if (result === true) {
        DeletePromotionV2(id).then((response) => {
          console.log("Response after delete", response);
          if (response.msg === 'Promotion has Deleted Successfully') {
            swal({
              icon: "success",
              content: (<div>
                <h1 style={{ color: "darkred" }}>Promotion Deleted</h1>
                <p>
                  Primotion is deleted sucessfully
                </p>
              </div>)
            }).then(() => {
              setPromotionList(() => promotionList.filter((promotionList) => promotionList.id !== id));
            })
          } else if (response.msg === 'Error while deleting Promotion') {
            swal({
              icon: "error",
              content: (<div>
                <h1 style={{ color: "darkred" }}>Error</h1>
                <p>
                  Error While deleting Primotion
                </p>
              </div>)
            })
          } else {
            swal({
              icon: "success",
              content: (<div>
                <h1 style={{ color: "darkred" }}>Promotion Deleted</h1>
                <p>
                  Primotion is deleted sucessfully
                </p>
              </div>)
            })
          }
        })

      } else {
        console.log("");
      }
    })

  }

  return (
    <Container className="dashboard">
      <Row>
        <Card>
          <CardBody className='mb-2'>
            <Row>
              <Col sm={10} md={10} lg={10} >
                <h3>Promotions List</h3>
              </Col>
              <Col sm={2} md={2} lg={2} >
                <Button onClick={() => handleAddPromotion()} color="warning" size='sm' className='m-0'>Add Promotion</Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>

      {promotionList.length > 0 && <Row>
        <Col sm={12} md={12} lg={12}>
          <Card>
            <CardBody>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Main Court</th>
                    <th>Price</th>
                    <th>Additional Slot Price</th>
                    <th>Times</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    promotionList?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data?.date}</td>
                          <td>{data?.maincourt?.courtName}</td>
                          <td>{data.price}</td>
                          <td>{data.additional_slot_price}</td>
                          <td>{data.times}</td>
                          <td><div className="modal-edit-btn" onClick={() => handleEdit(data.id)} ><AiOutlineEdit style={{ fontSize: 20 }} /></div></td>
                          <td><Button color="danger" size="sm" onClick={() => deletePromotion(data.id)}>Delete</Button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>}

      {userPromotionList.length > 0 && <Row>
        <Col sm={12} md={12} lg={12}>
          <Card>
            <CardBody>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Booking Id</th>
                    <th>User Id</th>
                    <th>Booking Status</th>
                    <th>Main Court</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    userPromotionList?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data?.id}</td>
                          <td>{data.bookingId}</td>
                          <td>{data.user}</td>
                          <td>{data.bookingstatus}</td>
                          <td>{data?.maincourt}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>}
    </Container>
  )
};

export default PromotionV2List;
