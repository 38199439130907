import React from 'react'
import SidebarCategory from '../SidebarCategory'
import SidebarLink from '../SidebarLink'
import { useSelector, useDispatch } from 'react-redux';
import { adminCounterBooking, adminOnly, getValueByKey, hasAccess } from '../../../../Utils/AWSBucket/AWSConfiguration';

const AppConfigurationsSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="App Configuration's" icon="smartphone">
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Skill Level Verification" route="/verify-skill-level" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Offer Docs" route="/offer-docs" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Add Bookings" route="/courtbooking" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Ratings" route="/rating" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Padelholic" route="/listpadelholic" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="User's Ratings" route="/user_ratings" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Coupon" route="/coupon" />}
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Coupon usage" route="/coupon-booking-list" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Pages" route="/getpage" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Offers" route="/offer-list" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Miscellaneous" route="/misc-list" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Push Notification" route="/push_notification" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Promotion" route="/promotion" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="PromotionsV2" route="/promotionsv2-list" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Package List" route="/package-list" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Rewards" route="/rewards-list" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Match Categories" route="/match-categories-list" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Unsuccessful Transaction" route="/unsucess-transaction" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Coaches List" route="/coaches-list" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="CS List" route="/customer-support-list" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Event Registration" route="/event-registration-tabs" />}
            {/* {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Sports" route="/sport-list" />} */}
        </SidebarCategory>
    );
};

export default AppConfigurationsSidebar;