import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import classNames from 'classnames';
import { AiOutlineEdit } from 'react-icons/ai';
import UpdateAdminModalForm from '../UpdateAdminModalForm';

const UpdateAdminUserModal = ({
    color, btn, colored, header, title, data, recallAdmin
}) => {
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(prevState => !prevState);
    };

    let Icon;

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    };

    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    return (
        <>
            <div className="modal-edit-btn" onClick={toggle}>
                <AiOutlineEdit style={{ fontSize: 16 }} />
            </div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalClassName={`modal ltr-support`}
                className={`modal-dialog--${color} ${modalClass}`}
                size="xl"
                style={{ minWidth: 950 }}
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <UpdateAdminModalForm userData={data} showHeader={false} toggle={toggle} updated={recallAdmin} />
            </Modal>
        </>
    );
};

export default UpdateAdminUserModal;