import React from 'react';
import {
  Card,
  CardBody,
  Col, Container, Row,
} from 'reactstrap';
import UsersListCard from './components/CourtTypeEditCard';

const UsersIndex = () => {
  return (
    <Container>
      <UsersListCard />
    </Container>
  );
}
export default UsersIndex;
