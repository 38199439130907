import React from 'react'
import SidebarCategory from '../SidebarCategory';
import SidebarLink from '../SidebarLink';
import { useSelector, useDispatch } from 'react-redux';
import { adminCounterBooking, adminOnly, hasAccess, hideForAll } from '../../../../Utils/AWSBucket/AWSConfiguration';

const LocationSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Location's" icon="map-marker">
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Main Court" route="/list_main_court" />}
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Padel Court" route="/all_courts" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Add on's" route="/list_addons" />}
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Court Type" route="/list_court_type" />}
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Court Category" route="/list_court_category" />}
        </SidebarCategory>
    );
};

export default LocationSidebar;