import React from 'react'
import SidebarCategory from './SidebarCategory'
import SidebarLink from './SidebarLink'
import Dashboard from './SidebarList/Dashboard'
import BookingsSidebar from './SidebarList/BookingsSidebar'
import PaymentSidebar from './SidebarList/PaymentSidebar'
import MatchMakingsidebar from './SidebarList/MatchMakingsidebar'
import PackagesSidebar from './SidebarList/PackagesSidebar'
import UserSidebar from './SidebarList/UserSidebar'
import AdsSpaceSidebar from './SidebarList/AdsSpaceSidebar'
import Homepage from './SidebarList/Homepage'
import ManageUser from './SidebarList/ManageUser'
import PermissionsSidebar from './SidebarList/PermissionsSidebar'
import PadelgramSidebar from './SidebarList/PadelgramSidebar'
import { useSelector, useDispatch } from 'react-redux';
import { adminCounterBooking, adminOnly, allAccessRole, getValueByKey, hasAccess, hideForAll } from '../../../Utils/AWSBucket/AWSConfiguration'
import SettingSidebar from './SidebarList/SettingSidebar'
import AppConfigurationsSidebar from './SidebarList/AppConfigurationsSidebar'
import LocationSidebar from './SidebarList/LocationSidebar'

const SidebarContent = () => {
  const authslice = useSelector((state) => state.auth);

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <Dashboard />}
        {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <BookingsSidebar />}
        {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <AppConfigurationsSidebar />}
        {hasAccess(authslice?.loggedAdminRole, adminOnly) && <LocationSidebar />}
        {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <PaymentSidebar />}
        {hasAccess(authslice?.loggedAdminRole, adminOnly) && <MatchMakingsidebar />}
        {hasAccess(authslice?.loggedAdminRole, adminOnly) && <PackagesSidebar />}
        {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <UserSidebar />}
        {hasAccess(authslice?.loggedAdminRole, adminOnly) && <AdsSpaceSidebar />}
        {hasAccess(authslice?.loggedAdminRole, adminOnly) && <Homepage />}
        {hasAccess(authslice?.loggedAdminRole, adminOnly) && <ManageUser />}
        {hasAccess(authslice?.loggedAdminRole, adminOnly) && <PermissionsSidebar />}
        {hasAccess(authslice?.loggedAdminRole, adminOnly) && <PadelgramSidebar />}
        {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SettingSidebar />}
      </ul>
      <SidebarLink title="Log Out" icon="exit" />
    </div>
  );
};

export default SidebarContent;