import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import axios from '../../customAxios';
import { adminEndPoints } from '../../endPoints/AdminEndPoints';
import { AiFillEye, AiOutlineEdit } from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UpdateAdminUserModal from './modal/UpdateAdminUserModal';

const AdminUsers = () => {
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
  let navigate = useNavigate();

  const tableConfigCouponLog = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [1000, 2000, 3000, 4000],
    placeholder: "Search by Name...",
  };

  const initialAdminUserFilter = {
    email: "",
    phone_no: "",
    role: "",
    first_name: "",
    last_name: "",
    page: 1,
    page_size: 10
  };

  // templates
  const idTemplate = (data, index) => (<div className='bookingId right-space'>{index + 1}</div>);
  const nameTemplate = (data) => (<div className="right-space">{data?.first_name} {data?.last_name}</div>);
  const emailTemplate = (data) => (<div className="right-space">{data?.email}</div>);
  const phoneNumberTemplate = (date) => (<div className="right-space user-phone">{date.phone_no === "null" ? "" : date.phone_no}</div>);
  const roleTemplte = (data) => (<div className="right-space">{data.role}</div>);
  const actionTemplates = (data) => (<div className="right-space" onClick={() => navigate(`/user_details/${data?.id}`)}><div className="modal-view-btn m-0"><AiFillEye /></div></div>);
  const editTemplate = (data) => (
    <div className="right-space"><UpdateAdminUserModal color="primary" header title={"Update Admin User"} data={data} recallAdmin={recallAdmin} /></div>
  );

  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [initialPage, setInitialPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [paginationKey, setPaginationKey] = useState(0);
  const [adminUserFilter, setAdminUserFilter] = useState(initialAdminUserFilter);
  const { pageLimits } = useSelector((state) => state.config);
  const roleList = ["admin", "manager", "counterbooking", "coaches"];

  const couponHearders = [
    {
      Header: "#",
      accessor: "id",
      disableGlobalFilter: false,
      width: 65
    },
    {
      Header: "#",
      accessor: "usersId",
      disableGlobalFilter: true,
      width: 50
    },
    {
      Header: "Name",
      accessor: "name",
      disableGlobalFilter: true,
      width: 80
    },
    {
      Header: "Email",
      accessor: "email",
      disableGlobalFilter: true,
      width: 120
    },
    {
      Header: "Phone No",
      accessor: "phone",
      disableGlobalFilter: true,
      width: 120,
    },
    {
      Header: "Role",
      accessor: "role",
      disableGlobalFilter: true,
      width: 160,
    },
    {
      Header: "Edit",
      accessor: "edit",
      disableGlobalFilter: true,
      width: 50,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableGlobalFilter: true,
      width: 50,
    }
  ];

  const getAdminUsers = (filterPayload) => {
    try {
      axios.get(`${adminEndPoints.adminUsersList}`, { params: filterPayload }).then((response) => {
        const usersListResponse = response.data.results.map((data, index) => {
          return {
            ...data,
            usersId: idTemplate(data, ((adminUserFilter?.page - 1) * adminUserFilter?.page_size + index)),
            name: nameTemplate(data),
            email: emailTemplate(data),
            phone: phoneNumberTemplate(data),
            role: roleTemplte(data),
            actions: actionTemplates(data),
            edit: editTemplate(data)
          }
        });
        setUsersList(usersListResponse);
        setPageCount(Math.ceil(response.data.count / adminUserFilter?.page_size));
      });
    } catch (error) {
      console.log("error", error);
    };
  };

  const handlePagination = (page) => {
    setAdminUserFilter({ ...adminUserFilter, page: page.selected + 1 });
  };

  const handleLimitChange = (e) => {
    setInitialPage(0);
    setPaginationKey((prevKey) => prevKey + 1);
    setAdminUserFilter({ ...adminUserFilter, page_size: e.target.value });
  };

  const resetSearch = () => {
    reset();
    setInitialPage(0);
    setPaginationKey((prevKey) => prevKey + 1);
    setAdminUserFilter({ ...initialAdminUserFilter, page: 1 });
  };

  const onSubmit = (data) => {
    try {
      setInitialPage(0);
      setPaginationKey((prevKey) => prevKey + 1);
      setAdminUserFilter({ ...adminUserFilter, ...data, page: 1 });
    } catch (error) {
      console.log("error-->", error);
    }
  };

  const recallAdmin = () => {
    getAdminUsers(adminUserFilter);
  };

  useEffect(() => {
    getAdminUsers(initialAdminUserFilter);
  }, []);

  useEffect(() => {
    getAdminUsers(adminUserFilter);
  }, [adminUserFilter]);

  return (
    <Row>
      <Col sm={12} md={12} lg={12}>
        <Card>
          <CardBody>
            <h4>Admin Users</h4>
          </CardBody>
        </Card>
      </Col>
      <Col sm={12} md={12} lg={12} className='mt-2'>
        <Card>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row className='align-items-end'>
                <Col sm={10} md={10} lg={10}>
                  <div className="form form__form-group">
                    <div className="form__form-group-field d-flex">
                      <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                        <span>Name</span>
                        <input
                          type="text"
                          className="custom-css-dp"
                          placeholder='Name'
                          {...register("first_name", { required: false })}
                        />
                      </div>
                      <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                        <span>Email</span>
                        <input
                          type="text"
                          className="custom-css-dp"
                          placeholder='Email'
                          {...register("email", { required: false })}
                        />
                      </div>
                      <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                        <span>Phone no</span>
                        <input
                          type="text"
                          className="custom-css-dp"
                          placeholder='Phone no'
                          {...register("phone_no", { required: false })}
                        />
                      </div>
                      <div className="d-flex flex-column w-100" style={{ marginRight: '5px' }}>
                        <span>Role</span>
                        <input
                          type="text"
                          className="custom-css-dp"
                          list="role"
                          placeholder="Role"
                          {...register("role", { required: false })}
                        />
                        <datalist id="role">
                          <option value="">Select Role</option>
                          {roleList.map((role) => {
                            return (<option data-value={role}>{role}</option>)
                          })}
                        </datalist>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={2} md={2} lg={2}>
                  <Row>
                    <Col sm={6} md={6} lg={6} className='px-1'>
                      <Button size='sm' color="warning" className='m-1 w-100 px-2'>Apply</Button>
                    </Col>
                    <Col sm={6} md={6} lg={6} className='px-1'>
                      <Button size='sm' color="secondary" className='m-1 w-100 px-2' onClick={() => resetSearch()}>Clear</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Col>
      <Col sm={12} md={12} lg={12} className='mt-2'>
        <Card>
          <CardBody className='mb-4'>
            <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
              <ReactTableBase
                id="table-to-xls"
                key={withSearchEngine ? "searchable" : "common"}
                columns={couponHearders}
                data={usersList}
                tableConfig={tableConfigCouponLog}
              />
            </div>
            <div className="custom-pagination">
              <div className="records-per-page">
                <select
                  value={adminUserFilter?.page_size}
                  onChange={handleLimitChange}
                  name="limit"
                  id="select-pagination">
                  {pageLimits.map((pageLimits) => (
                    <option value={pageLimits}>{pageLimits}</option>
                  ))}
                </select>
                &nbsp;&nbsp;&nbsp;records per page
              </div>
              <ReactPaginate
                key={paginationKey}
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePagination}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={initialPage}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AdminUsers