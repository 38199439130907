import React from 'react'
import SidebarCategory from '../SidebarCategory';
import SidebarLink from '../SidebarLink';
import { useSelector, useDispatch } from 'react-redux';
import { adminCounterBooking, adminOnly, hasAccess, hideForAll } from '../../../../Utils/AWSBucket/AWSConfiguration';

const PaymentSidebar = () => {
    const authslice = useSelector((state) => state.auth);
    return (
        <SidebarCategory title="Payment" icon="cart">
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Transaction's" route="/transactions" />}
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Quick Pay" route="/quick-pay-details" />}
            {hasAccess(authslice?.loggedAdminRole, adminOnly) && <SidebarLink title="Gift Card" route="/e-giftcard" />}
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Tournaments" route="/tournaments" />}
            {hasAccess(authslice?.loggedAdminRole, adminCounterBooking) && <SidebarLink title="Kids Camp" route="/kids-summer-camp" />}
        </SidebarCategory>
    );
};

export default PaymentSidebar;