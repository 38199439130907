import React from 'react';
import { ButtonGroup, Col, Container, Row, Button, Card, CardBody } from 'reactstrap';
import Promotions from './components/Promotions';

const ExamplePage = () => {



  return (
    <Container className="dashboard">
      <Promotions />
    </Container>
  )
};

export default ExamplePage;
